import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"
import ImageDownloadSocial from "../../../components/ImageDownloadSocial"



const IndexPage = () => {
    const root = "/img/download/power-hour"

    return (
        <Layout pageTitle="Power Hour &amp; Summit - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/events/power-hour">Back</Link>
                </Col>
            </Row>

           <Row className="mt-5">
                <Col>
            <h1>General Promo</h1>
            </Col>
    </Row>
                
            <ImageDownload
            root ="/img/download/power-hour"
            filename="power-hour"
            name="Power Hour General Promo"
            text="Use this to promote Business Lunch events on your social media"
            text2=""
            squareHref="https://www.canva.com/design/DAFqeS8_7QI/dnujvKk1pZlI1KZyAIN7vQ/view?utm_content=DAFqeS8_7QI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFqefFT_38/5Tl0jEPgq5BpRKa27lZ58w/view?utm_content=DAFqefFT_38&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1GSqSN7N2IH3-a1uskMVHJ58BwwQQC3UQeiPVwxlHnSw/edit?usp=sharing"
            />  
        

            
        </Layout>

        
    )
}

export default IndexPage
